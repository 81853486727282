@import "variables.less";

body {
    font-family: "Courier New", Courier, monospace;
    font-size: 16px;
    background-image: url("/images/background.gif");
}


html {
    height: 100%;
}
body {
    padding-top: 0;
}

@media (min-width: 768px) {
  body.footer-fixed {
    position: relative;
    padding-bottom: 3*@dist;
    min-height: 100%;
  }
}

/* footer */
footer {
    padding-top: @dist;
    background-color:transparent;
    color:black;
    font-size: 14px;

    p, a {
        background-color:white;
    }
}
@media (min-width: 768px) {
  footer.footer-fixed {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
/* footer */

/* general */

.px-0 {
    padding-left:0;
    padding-right:0;
}

.section-heading {
    margin-bottom: 2*@dist;
}

.dist1 {
    margin-top: 1*@dist_m;
}
.dist2 {
    margin-top: 2*@dist_m;
}
.dist3 {
    margin-top: 3*@dist_m;
}
.dist4 {
    margin-top: 4*@dist_m;
}
.dist6 {
    margin-top: 5*@dist_m;
}
.dist8 {
    margin-top: 6*@dist_m;
}
@media (min-width: 768px) {
    .dist1 {
        margin-top: 1*@dist;
    }
    .dist2 {
        margin-top: 2*@dist;
    }
    .dist3 {
        margin-top: 3*@dist;
    }
    .dist4 {
        margin-top: 4*@dist;
    }
    .dist6 {
        margin-top: 5*@dist;
    }
    .dist8 {
        margin-top: 6*@dist;
    }
}

h5 {
    font-weight: 700;
}

.content .container .row
{
    border-color:black;
    border-style: solid;
    border-width: 0 @dist;

    background-color:white;
}
.content .container .row:last-child
{
    border-bottom-width: @dist;
}

.content.home .container {
    padding-top: 0;
}

.content-dark .container .row {
    color: white;
    background-color: black;
}

a,
a:hover,
a:focus,
a:active,
a.active {
	outline: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

a {
	color: @brand-primary-dark;
}

a:hover,
a:focus,
a:active,
a.active {
	color: darken(@brand-primary-dark, 10%);
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}
/* general */

/* navbar */
.navbar {
    margin-bottom: 0;
    font-size: 18px;

    .lang {
        font-size: 14px;
        text-transform: uppercase;
    }
}
.navbar-brand {
    padding-left: 0px;
    padding-top: 7px;
}
.navbar-brand > img {
    width: 80%;
}
@media(min-width:768px) {
    .navbar {
        font-size: 20px;
        font-weight: 800;

        .container {
            padding-top: @dist/2;
        }
    }

    .navbar-right {
        margin-top: -10px;
        margin-right: -30px;
    }

    .navbar-brand {
        padding-right: 20px;
        padding-top: 10px;
    }
    .navbar-brand > img {
        width: 100%;
    }
}
@media(min-width:992px) {
    .navbar {
        font-size: 24px;
    }

    .lang-first {
        padding-left: 4*@dist;
    }
}
.navbar-inverse {

	background-color: black;
	border-color: transparent;

    .navbar-nav {
        margin:0;
    }

    .navbar-nav>.active>a,
    .navbar-nav>.active>a:hover,
    .navbar-nav>.active>a:focus,
    .navbar-nav > .open > a,
    .navbar-nav > .open > a:focus,
    .navbar-nav > .open > a:hover
    {
        background-color: transparent;
    }
}

@media(min-width:768px) {
    .navbar {
        border-radius: 0;

        .container {
            background-color: black;
        }
    }
    .navbar-inverse {
        background-color: transparent;
        border:0;
    }
}
/* navbar */

/* icons */
.content.contact
{
    .fa {
        font-size: 18px;
    }
}
footer
{
    .fa {
        font-size: 16px;
    }
}
/* icons */


/* form */
.form-control {
    margin-bottom: @dist_form;
}

.btn-primary {
    background-color: @brand-primary;
    border-color: @brand-primary-dark;
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.focus:active, .btn-primary:active:focus, .btn-primary:active:hover {
    background-color: @brand-primary-dark;
    border-color: @brand-primary-dark;
}

.label-block {
    display: block;
}
.form-group.required label:before {
  content:"*";
  color:black;
}
/* form */


/* gallery */
div.gallery {
    padding-left: @dist / 2;
    padding-right: @dist / 2;
    margin-bottom: @dist;
}

.modal-content {
    border-radius: 0;
}
/* gallery */

/* about */
#img-ourico-about {
    margin-top: 2*@dist;
}
/* about */


/* store */
#price {
    margin-top: -@dist;
    font-weight: bolder;
}

.img-swap-src {
    cursor: pointer;
}
.img-swap-src.active {
    opacity: 0.5;
}

div.row {
    &.products {
        .col {
            height: 40px;
        }
        select {
            min-width: 100%;
        }

        .btn-default {
            border-color: #666;
            background-color: #FFF;
            border-radius: 0;
            width: 62px;
        }
        .btn-default:hover {
            border-color: #333;
        }
    }
}
/* store */